import React from 'react';

import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';

Font.register({
  family: 'Roboto',
  src: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    // margin: 20,
    // padding: 20
    // fontFamily: "Roboto",
  },
  section: {
    margin: 20,
    padding: 10,
  },
  section2: {
    margin: 20,
    padding: 20,
  },
  fontBold: {
    fontWeight: '900',
  },
  header: {
    margin: 20,
    marginBottom: 0,
    padding: 10,
    paddingBottom: 0,
    textAlign: 'center',
  },
});

// Create Document Component
const PdfCreate = (props) => {
  const { /*qrcode,*/ data } = props || {};

  /*
  'data:' + mimeType + ';base64,' + base64Data
  */

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          {/** loghi doppi tutor */}
          {(parseInt(data?.idParent) > 0 && data?.logoTutor !== '') ? <div
            style={{
              textAlign: "right",
              marginTop: "0px",
              marginBottom: "0px",
              position: "relative",
            }}>
            <div
              style={{
                position: "absolute",
                left: "0px",
                top: "0px",
                textAlign: "left",
              }}>
              <Image
                style={{ height: "65px", marginLeft: "40px"}}
                src={'data:' + data?.mimeApp + ';base64,' + data?.logoApp}
              />
            </div>
            <div
              style={{
                position: "absolute",
                right: "0px",
                top: "0px",
                textAlign: "right",
              }}>
              <Image
                style={{ height: "65px", marginRight: "40px" }}
                src={'data:' + data?.mimeTutor + ';base64,' + data?.logoTutor}
              />
            </div>
          </div> : ''}

          {/** loghi singolo */}
          {(parseInt(data?.idParent) === 0 || data?.logoTutor === '') ? <div
            style={{
              textAlign: "center",
              marginTop: "0px",
              marginBottom: "0px",
              width: "100%",
            }}>
              <Image
                style={{ height: "50px", width: "50px", margin: "0 auto"}}
                src={'data:' + data?.mimeApp + ';base64,' + data?.logoApp}
              />
          </div> : ''}

          <Text
            style={
              parseInt(data?.idParent) === 0
                ? { fontSize: '14px', marginTop: '25px' }
                : { fontSize: '14px', marginTop: '80px' }
            }
          >
            Lab.Mar. del Dott. Calogero Cecato
          </Text>
          <Text style={{ fontWeight: '900', fontSize: '12px', marginTop: '5px' }}>STUDIO CONSULENZA HACCP</Text>
          <Text style={{ fontSize: '10px', marginTop: '5px' }}>
            Iscritto al n. 2021/356 del registro regionale della regione Sicilia per la formazione degli alimentaristi
          </Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              fontSize: '18px',
              fontWeight: '900',
              textAlign: 'center',
              marginTop: '0px',
              marginBottom: '20px',
            }}
          >
            ATTESTATO N. {data?.progress}/{data?.aa}
          </Text>
          <div className="flex">
            <Text
              style={{
                fontSize: '12px',
                lineHeight: '1.3',
                textAlign: 'center',
              }}
            >
              {'Si Attesta che il Sig./Sig.ra'}{' '}
              <Text
                style={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}
              >
                {data?.name?.charAt(0).toUpperCase() + data?.name?.slice(1)}{' '}
                {data?.surname?.charAt(0).toUpperCase() + data?.surname?.slice(1)}
              </Text>
            </Text>
          </div>
          <Text
            style={{
              fontSize: '12px',
              lineHeight: '1.3',
              textAlign: 'center',
              marginTop: '7px',
            }}
          >
            {'Codice Fiscale'}{' '}
            <Text
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              {data?.fiscalCode?.toUpperCase()}{' '}
            </Text>
          </Text>
          <Text
            style={{
              fontSize: '12px',
              lineHeight: '1.4',
              textAlign: 'center',
              marginTop: '7px',
            }}
          >
            Nato/a a{' '}
            <Text
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {data?.birthCity?.charAt(0).toUpperCase() + data?.birthCity?.slice(1)}
            </Text>{' '}
            Provincia
            <Text
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              ({data?.birthProvince.toUpperCase()})
            </Text>{' '}
            il{' '}
            <Text
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {moment(data?.dateOfBirth).format('DD/MM/YYYY')}{' '}
            </Text>
          </Text>
          <Text style={{ fontSize: '12px', lineHeight: '1.4', marginTop: '20px' }}>
            Ha frequentato il corso per alimentarista per una durata di {data?.courseMinTime} ore tenutosi in modalità
            FAD asincrona dal {moment(data?.courseStartedAt).format('DD/MM/YYYY')} al{' '}
            {moment(data?.courseEndedAt).format('DD/MM/YYYY')}{' '}
          </Text>
          <Text
            style={{
              fontSize: '18px',
              lineHeight: '1.4',
              textAlign: 'center',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            {"SUPERATO CON PROFITTO L'ESAME FINALE"}
          </Text>
          <Text
            style={{
              fontSize: '12px',
              lineHeight: '1.4',
              marginBottom: '0px',
              textAlign: 'center',
            }}
          >
            {'sostenuto in data'} {moment(data?.courseEndedAt).format('DD/MM/YYYY')}
          </Text>

          <div style={{ marginTop: '20px' }}>
            <Text style={{ fontSize: '12px', lineHeight: '1.4' }}>
              {
                'Il presente attestato è valido 3 anni a partire dalla data di rilascio e deve essere rinnovato 30 giorni prima della scadenza'
              }
            </Text>
            <Text
              style={{
                fontSize: '12px',
                lineHeight: '1.4',
                marginTop: '20px',
              }}
            >
              Data di rilascio {moment(data?.releasedAt).format('DD/MM/YYYY')}
            </Text>
          </div>

          <div
            style={{
              textAlign: 'right',
              marginTop: '20px',
              marginBottom: '0px',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                textAlign: 'center',
              }}
            >
              {/* <div style={{ display: "block" }}> */}
              <Image
                style={{
                  width: '80px',
                  height: '80px',
                  margin: '0 auto',
                }}
                src={props.qrcode}
              />
              {/* </div> */}
              <Text
                style={{
                  fontSize: '12px',
                  marginTop: '5px',
                }}
              >
                Verifica questo attestato
              </Text>
            </div>
            <div
              style={{
                position: 'absolute',
                right: '0',
                top: '0',
                textAlign: 'center',
              }}
            >
              <div>
                <Text style={{ fontSize: '12px' }}>Il responsabile del corso</Text>
                <Image
                  style={{
                    width: '100px',
                    height: '110px',
                    margin: '0 auto',
                  }}
                  src={'/images/signature.jpeg'}
                />
                <Text style={{ fontSize: '12px' }}>Dott. Calogero Cecato</Text>
              </div>
            </div>
          </div>
        </View>
        <View style={styles.section}>
          <div style={{ marginTop: '160px' }}>
            <Text
              style={{
                fontSize: '12px',
                lineHeight: '1.4',
                textAlign: 'center',
              }}
            >
              {data?.courseTitle}
            </Text>
            {/* <Text
              style={{
                fontSize: "12px",
                lineHeight: "1.4",
                textAlign: "center",
              }}
            >
              {
                "Gli attestati hanno una validità per il principio di reciprocità ai sensi della Direttiva Europea 2005/36/CE recepita con D.Lgs. 206/2007"
              }
            </Text> */}
          </div>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        {data?.courseCode === 'haccp12' && (
          <View style={styles.section2}>
            <Text
              style={{
                fontSize: '15px',
                marginTop: '40px',
                fontWeight: 'bold',
              }}
            >
              {'NOTA INFORMATIVA'}
            </Text>
            <Text
              style={{
                marginTop: '20px',
                fontSize: '14px',
                fontWeight: 'black',
              }}
            >
              {
                'Corso Base di 12 ORE rivolto agli Addetti che manipolano alimenti (livello 2) e Responsabili dell’industria alimentare e addetti che non manipolano alimenti.'
              }
            </Text>
            <Text
              style={{
                marginTop: '20px',
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '1.4',
              }}
            >
              {
                'Il corso è tenuto da LAB.MAR. del Dott. Calogero Cecato, avente sede in Sciacca (AG), in conformità a quanto stabilito dal REG. CE 852/04 e ai sensi del decreto assessoriale Regione Sicilia n.698 del 3 Agosto 2022 della Regione Sicilia, ed è valido in tutto il territorio nazionale per il principio di reciprocità, Direttiva Europea 2005/36/CE recepita con il D.lgs 206/2007.'
              }
            </Text>
            <Text style={{ marginTop: '5px', fontSize: '13px' }}>
              {'Il test finale e la relativa documentazione sono conservati presso il nostro ufficio.'}
            </Text>
            <Text
              style={{
                marginTop: '40px',
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              {'PROGRAMMA DEL CORSO PER ALIMENTARISTA DI 12 ORE MODALITA’ FAD ASINCRONA'}
            </Text>

            {/* listato */}
            <Text
              style={{
                marginTop: '10px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- rischi e pericoli alimentari: chimici, fisici, microbiologici; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {
                '- igiene personale e corrette prassi per la prevenzione delle malattie trasmissibili con gli alimenti; '
              }
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- obblighi e responsabilità dell’operatore del settore alimentare; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- requisiti generali in materia d’igiene; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- principi di pulizia e disinfezione degli ambienti e delle attrezzature; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- modalità di conservazione degli alimenti; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- norme di buona pratica atte ad evitare la presenza di allergeni occulti negli alimenti; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- principali norme in materia di sicurezza degli alimenti e sanzioni per le violazioni di tali norme;'}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- tracciabilità e rintracciabilità degli alimenti; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- microrganismi patogeni e meccanismi di contaminazione biologica e chimica degli alimenti; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {
                '- autocontrollo, nozioni sui prerequisiti (lotta agli infestanti, smaltimento dei rifiuti, gestione dei sottoprodotti di origine animale, etc…); '
              }
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- autocontrollo: nozioni sulle procedure basate sui principi del sistema HACCP; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {
                '- nozioni di base sugli alimenti contenenti glutine, allergeni e relativa normativa e MOCA (materiali a contatto degli alimenti) '
              }
            </Text>
          </View>
        )}

        {data?.courseCode === 'haccp6' && (
          <View style={styles.section2}>
            <Text
              style={{
                fontSize: '15px',
                marginTop: '40px',
                fontWeight: 'bold',
              }}
            >
              {'NOTA INFORMATIVA'}
            </Text>
            <Text
              style={{
                marginTop: '20px',
                fontSize: '14px',
                fontWeight: 'black',
              }}
            >
              {'Corso di Aggiornamento 6 ORE rivolto a coloro che hanno un attestato scaduto o in scadenza.'}
            </Text>
            <Text
              style={{
                marginTop: '20px',
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '1.4',
              }}
            >
              {
                'Il corso è tenuto da LAB.MAR. del Dott. Calogero Cecato, avente sede in Sciacca (AG), in conformità a quanto stabilito dal REG. CE 852/04 ai sensi del decreto assessoriale Regione Sicilia n.698 del 3 Agosto 2022 della Regione Sicilia, ed è valido in tutto il territorio nazionale per il principio di reciprocità, Direttiva Europea 2005/36/CE recepita con il D.lgs 206/2007.'
              }
            </Text>
            <Text style={{ marginTop: '5px', fontSize: '13px' }}>
              {'Il test finale e la relativa documentazione sono conservati presso il nostro ufficio.'}
            </Text>
            <Text
              style={{
                marginTop: '40px',
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              {'PROGRAMMA DEL CORSO PER ALIMENTARISTA DI 6 ORE MODALITA’ FAD ASINCRONA'}
            </Text>

            {/* listato */}
            <Text
              style={{
                marginTop: '10px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- microrganismi patogeni e condizioni che favoriscono o inibiscono la relativa crescita; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {
                '- corretta conservazione degli alimenti e trattamenti di abbattimento microbico, con particolare riferimento alla catena del freddo e alle corrette temperature di conservazione degli alimenti; '
              }
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- prevenzione delle tossinfezioni alimentari; '}
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {
                '- nozioni di base sugli alimenti contenenti glutine, allergeni e relativa normativa e MOCA (materiali a contatto degli alimenti) '
              }
            </Text>
            <Text
              style={{
                marginTop: '5px',
                fontSize: '13px',
                paddingLeft: '10px',
              }}
            >
              {'- piani di autocontrollo basati sul sistema HACCP. '}
            </Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default PdfCreate;
